<template>
  <div class="ds-wrapper">
    <div class="grid grid-cols-2 w-full h-full bg-neutral-900 rounded-2xl pt-6 px-8 pb-8">
      <div class="request-block border-solid border-b-0 border-l-0 border-t-0 border-r border-indigo-600">
        <div class="flex justify-between items-center cursor-pointer pr-6">
          <h5 class="text-white">REQUEST</h5>
          <div class="flex items-center gap-x-2 ml-auto copy-content py-1 px-2">
            <vs-icon icon="content_copy" class="text-neutral-400" size="15px" @click="copyCode('payloadRequest')"></vs-icon>
            <p @click="copyCode('payloadRequest')" class="text-sm">Copy all</p>
          </div>
        </div>
        <vue-accordion class="prism-code pt-0 pr-8 pb-12 rounded-bl-2xl">
          <template v-slot:code>
            <pre><Prism class="language-js" language="javascript" style="background:none">{{ payloadRequest }}</Prism></pre>
          </template>
        </vue-accordion>
      </div>
      <div class="response-block">
        <div class="flex justify-between items-center cursor-pointer pl-6">
          <h5 class="text-white">RESPONSE</h5>
          <div class="flex items-center gap-x-2 ml-auto copy-content py-1 px-2">
            <vs-icon icon="content_copy" class="text-neutral-400" size="15px" @click="copyCode('payloadResponse')"></vs-icon>
            <p @click="copyCode('payloadResponse')" class="text-sm">Copy all</p>
          </div>
        </div>
        <vue-accordion class="prism-code pt-0 pl-6 pb-12 rounded-br-2xl">
          <template v-slot:code>
            <pre><Prism class="language-js" language="javascript" style="background:none">{{ payloadResponse }}</Prism></pre>
          </template>
        </vue-accordion>
      </div>
    </div>
  </div>
</template>

<script>
import Prism from "vue-prism-component";
import VueAccordion from "../../../../components/VueAccordion.vue";
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      payloadRequest: {},
      payloadResponse: {},
    }
  },
  components: { Prism, VueAccordion },
  methods: {
    ...mapActions("disbursement", ["fetchDisbursementById"]),

    async fetchDetail(id) {
      this.$vs.loading();

      await this.fetchDisbursementById(id)
        .then((result) => {
          const queueData = result.data.data.queueId;
          this.payloadRequest = queueData ? queueData.requestBody : {};
          this.payloadResponse = result.data.data && result.data.data.providerResponse ? JSON.parse(result.data.data.providerResponse) : {};
          this.$vs.loading.close();
        }).catch((ex) => {
          this.$vs.loading.close();
        });
    },

    copyCode(params) {
      navigator.clipboard.writeText(JSON.stringify(this[params], null, 1)).then(() => {
        this.showToastMessage("Payload copied to clipboard", "", "success");
      }).catch((ex) => {
        this.showToastMessage("Error copying payload", "Unable to copy payload to clipboard. Please try again.", "error");
      });
    },
  },
  mounted() {
    this.fetchDetail(this.$route.params.id);
  }
}
</script>
